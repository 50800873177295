.App {
  text-align: center;
  background-color: black;
}

.videoBox {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: black;
}

.video {
  width: 100%;
  height: 100%;
}

.listBox {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
}

.title {
  margin-top: 10px;
  color: white;
  font-size: 18pt;
  text-align: center;
  text-shadow: 0.1em 0.1em 0.2em rgb(52, 140, 241);
}

.item {
  margin-top: 6vw;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.imgBox {
  margin-left: 6vw;
  width: 35vw;
  height: 35vw;
  background: #000000;
  border: 2px solid;
  border-image: linear-gradient(
      180deg,
      rgba(94, 236, 255, 1),
      rgba(21, 188, 234, 1)
    )
    2 2;
}

.img {
  display: block;
  margin: 0 auto;
  width: 35vw;
  height: 35vw;
  background-repeat: no-repeat;
  background-size: contain;
}

.name {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
  color: white;
  font-size: 16pt;
  text-shadow: 0.1em 0.1em 0.2em rgb(52, 140, 241);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
