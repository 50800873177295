.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: scroll;
}

.icon {
  margin-top: 40px;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttonScaler {
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  position: relative;
}

.button {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
}

.title {
  position: relative;
  margin-top: 10px;
  margin-left: 10px;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.name {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
  color: #00c7ff;
  font-size: 16pt;
  text-shadow: 0.1em 0.1em 0.2em rgb(52, 140, 241);
}

.bar {
  margin-top: 5px;
  margin-right: 20px;
  height: 3px;
  background-color: #00c7ff;
}
